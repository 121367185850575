@import '../../Styles/breakpoints.scss';

.footer {
    position: fixed;
    width: 100%;
    bottom: 0;
 

    @include respond(tab-port-height) {
        position: unset;
        bottom: unset;
        width: unset;
        margin-top: 20px;
        z-index: 9000;
    }

    &__content {
        margin-top: 20px;
       padding: 10px 30px 3px 30px;
       background-color: #d8ddd8;
       display: flex;
       justify-content: space-between;
       @include respond(tab-port-height) {
           margin-top: 0;
           border-top: 1px solid #333;
       }

       
    @include respond(tab-port) {
        flex-direction: column;
        padding: 10px 0 45px 8px;
    }

    }

    &__left {
        display: flex;
    }

    &__icon {
        margin-right: 5px;
    }

    &__item {
        text-decoration: none;
        color: #333;
        display: flex;
        &--1 {
            margin-right: 20px;
        }
    }
}