.admin {
    width: 100vw;
    height: 100vh;
    background-color: #fff;

    &__navbar {
        background-color: rgb(216, 221, 216);
        display: flex;
        justify-content: space-between;
        padding: 10px 40px;

        
    @media only screen and (max-width: 600px) {
        // flex-direction: column;
        padding: 10px;
    }
    }

    &__navbarText {
        font-size: 20px;
        font-weight: 500;
    }

    &__download {
        display: flex;
        align-items:flex-start;
        margin-bottom: 0.4rem;
        cursor: pointer;

        &--text {
            font-size: 1rem;
        }
    }

    &__content {
        padding-left: 40px;
        padding-top: 40px;

        
    @media only screen and (max-width: 600px) {
        padding-left: 10px;
        padding-top: 10px;
    }
    }

    &__currentUser {
        font-size: 18px;
    }

    &__role {
        color: #2E7D32;
        margin-bottom: 20px;
        margin-top: 5px;
        font-size: 1rem;
    }
// bring this back later
    // .MuiDataGrid-main {
    //     font-size: 1.5rem;
    // }
    // .MuiInputBase-root {
    //     font-size: 1.8rem;
    // }

    // .MuiTablePagination-root {
    //     p {
    //         font-size: 1.6rem;
    //     }
    // }

    // .MuiMenuItem-root {
    //     font-size: 1.6rem !important;
    // }

    svg {
        height: 1.5rem;
        width: 1.5rem;
    }
    &__table {
        height: 600px;
        width:80vw;
        max-width: 1200px;
        &--loading {
            width: 100px;
            height: 100px;
        }
        
    @media only screen and (max-width: 600px) {
        width: 95%;
    }
        button {
            width: unset;
        }
    }

    &__dialog {
        padding: 20px;
        width: 300px;
        .dialogHeading {
            font-size: 17px;
            margin-bottom: 15px;
        }
        input {
            padding-left: 10px;
            font-size: 1rem;
        }

        button {
            font-size: 1rem;
        }
    }
}


.MuiListItem-button {
    font-size: 1rem !important;
}