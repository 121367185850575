.dataArea {
    width: 95vw;
    margin: 0 auto;

    &__top {
        display: flex;
        justify-content: space-between;
        margin-top: 1.5rem;
    }

    &__tabsBox {
        width: 70%;
        overflow-x: scroll;
    }

    .chartAndMapArea {
        height: 72vh;
        margin-top: 1rem;
        background-color: #23263C;
        border-radius: 1.2rem;
        padding: 1rem;
        overflow-y: scroll;
        &__content {
            display: flex;
        }

        &__left {
            width: 15rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        &__right {
            width: 100%;
            margin-left: 20px;
        }

        &__timeseries {
            color: #fff;
        }
    }
}

.markerPopup{
    padding: 0px 7px;
    list-style:none;
    display:flex;
    flex-direction:column;
    gap:4px
}
.singleSliderBox {
    position: fixed;
    bottom: 130px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10000;
    background-color: rgba(255, 255, 255, 0.536);
    border-radius: 8px;
    padding: 0.5rem;
    @media only screen and (max-width: 600px) {
        padding: 2rem;
    }
    &__drawer {
        bottom: 24.5rem
    }
    &__collapsed {
        bottom: 8rem
    }
    .singleSlider {
        
        width: 60rem;
        padding: 1rem;
       
        display: flex;
        align-items: center;

        @media only screen and (max-width: 600px) {
            width: unset;
        }

        &__line {
            width: 100%;
            height: 0.2rem;
            background-color: #24A0CA;
            position: absolute;
            left: 0;
            top: 2.5rem;
        }

        &__dates {
            display: flex;
            justify-content: space-between;
        
            margin-top: 0.6rem;
            align-items: center;
        } 

        &__date {
            color: #23283d;
            cursor: pointer;
            font-size: 1.3rem;
            &--selected {
                background-color: #23283d;
                color: #fff;
                padding: 0.5rem 1rem;
                border-radius: 2rem;
                cursor: unset;
            }
            &--future {
                color: #ccc;
                cursor: unset;
                pointer-events: none;
            }
        }

        &__detected {
            display: flex;
            justify-content: space-between;
        }

        &__detectedShip {
            height: 1rem;
            width: 1rem;
            border: 3px solid #fff;
            border-radius: 2rem;
            margin-bottom: 2rem;
            &--available {
                border:  3px solid #23283d;
            }
        
        }

        &__allPoints {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-left: 2rem;
            position: relative;

            @media only screen and (max-width: 600px) {
                display: none;
            }
        }

        &__point {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        .selectedShipIcon {
            margin-bottom: 1rem !important;
        }

        &__month {
            display: flex;
            justify-content: center;
            font-size: 1.4rem;
            font-weight: 500;
            margin-top: 1rem;
        }

        &__dropDowns {
            // margin-bottom: 2rem;
            min-width: 150px;
            @media only screen and (max-width: 600px) {
            display: flex;
            flex-direction: row;
            }
        }

        &__inUtc {
            position: absolute;
            bottom: 0.5rem;
            font-size: 1.3rem;
            font-weight: 400;
            letter-spacing: 0.5px;
            left: 45%;
        }

        &__close {
            position: absolute;
            right: 1rem;
            top: 0.5rem;
            color: rgb(108, 108, 108);
        }
    }
}

.creatNewTab {
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 10px;
    input {
        margin: 1rem 0 0.6rem 0;
        font-size: 1.2rem;
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 5px 10px;
    }
}

.lineTrough{
    text-decoration: line-through;
}


.creatNewTimeseries {
    padding: 1rem;
    width: 30rem;

    &__flex {
        display: flex;
        justify-content: space-between;
    }

    &__right {
        width: 18rem;
    }
    input {
        margin: 1rem 0 0.6rem 0;
        font-size: 1.2rem;
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 5px 10px;
        width: 80%;
    }

    &__dataTypes {
        width: 100%;
    }

    &__axisTypes {
        margin-top: 0.7rem;
    }

    #colorSelector {
        margin-top: 0.1rem;
    }

    &__submitBtn {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
    }

    &__movingAvg {
        margin-top: 0.5rem;
    }

    &__movingAvgTitle {
        margin-bottom: -0.6rem;
    }
}

.mainChart {
    height: 65vh;
    width: 100%;

    &__map {
        height: 71vh;
    }
}

.mainChart__customDateRange {
    width: 20rem;
    // height: 20rem;
    padding: 1.5rem 1.8rem;

    &--customrangeTitle {
        font-size: 1.2rem;
        margin-bottom: 1.5rem;
        border-bottom: 1px solid rgb(224, 224, 224);
        padding-bottom: 0.5rem;
    }
}

.chartAndMapArea__deleteTab {
    padding: 1rem;
    font-size: 1.2rem;
    width: 20rem;
}

.table__container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 92vw;
    padding-bottom: 10rem;
}

table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}   

td,
th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
    color: #fff;
 
}

tr:nth-child(even) {
    background-color: #8a8a8a;
}

.hyperionv2__downloadXLS {
    height: 500px;
    width: 800px;
    
}

.downloadXLS__dialog {
    .MuiPaper-root{
    max-width: 1200px !important;
    padding: 2rem;
    }
}