@mixin respond($breakpoint) {
    @if $breakpoint == phone {
      @media only screen and (max-width: 600px) {
        @content;
      } 
    }
    @if $breakpoint == tab-port {
      @media only screen and (max-width: 900px) {
        @content;
      }
    }
    @if $breakpoint == tab-port-height {
      @media only screen and (max-height: 900px) {
        @content;
      }
    }
    @if $breakpoint == tab-land {
      @media only screen and (max-height: 1200px) {
        @content;
      } 
    }
    // @if $breakpoint == laptop {
    //   @media only screen and (max-height: 1380px) {
    //     @content;
    //   } 
    // }

    @if $breakpoint == laptop {
        @media only screen and (max-height: 1500px) {
          @content;
        } 
      }

    @if $breakpoint == desk {
        @media only screen and (max-height: 1800px) {
          @content;
        }
      }

    @if $breakpoint == big-desktop {
      @media only screen and (min-height: 1800px) {
        @content;
      }
    }
  }
  