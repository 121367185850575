
::-webkit-scrollbar {
    width: 6px;
    height: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f5f6fc;
    border-radius: 5px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #a5a5a5;
    border-radius: 5px;
  }

  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* font-size: xx-large; */
    height: unset;
   
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  


  .loadingPage {
    height: 100vh;
    width: 100vw;
    background-color: #D8DDD8;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sr-only {
    display: none;
  }

  .blackcluster {
    width: 20px;
    height: 30px !important;
    text-align: center;
    font-size: 12px;
    border-radius: 50%;
    color: #fff;
    padding-top: 10px;
  }

  .markerCluster {
    // width: 20px;
    // height: 30px !important;
    text-align: center;
    // font-size: 12px;
    // border-radius: 50%;
    // color: #fff;
    // padding-top: 10px;
  }

  .map-marker-icon {
  
    svg {
      width: 100%;
      height: 100%;
    }
  }