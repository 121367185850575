.auth {
    height: 100vh;
    width: 100vw;
    background-color: #D8DDD8;
   
    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (max-width: 800px) {
        height: 100%;
    }

    &__mainHeading {
        font-size: 2rem;
        text-align: center;
        padding-top: 2rem;
        margin-bottom: 6rem;

        @media only screen and (max-width: 800px) {
            margin-bottom: 3rem;
        }
    }

    &__forms {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        // width: 100%;
        // height: 100%;

        @media only screen and (max-width: 800px) {
            flex-direction: column;
        }
    }

    &__formBox {
        @media only screen and (max-width: 800px) {
            width: 90%;
        }
        &--signIn {
            // margin-right: 60px;
            @media only screen and (max-width: 800px) {
                margin-bottom: 3rem;
            }
        }
    }

    label {
        font-size: 1rem;
    }

    &__header {
        font-size: 22px;
        margin-bottom: 10px;
    }

    &__inputs {
        input {
            width: 300px;
        }

        .button {
            margin-top: 20px;
        }
    }

    &__name, &__lastName {
        margin: 15px 0;
    }

 

    &__signup {
        width: 200px;
    }

    &__signIn {
        .auth__password {
            margin: 15px 0;
        }
    }

    #password {
        display: block;
        font-size: 1.6rem;
    }

    .switchForm {
        margin-bottom: 8px;
        font-size: 17px;
        .switch {
            button {
                text-transform: unset;
                font-size: 18px;
                margin-top: -1px;
            }
        }
    }

    .errorMessage {
        color: red;
        margin-top: 10px;
    }

    .resetPassword {
        margin-top: 20px;
    }

    button {
        font-size: 1rem !important;
    }
 
}
.askKevin {
    #client-snackbar{
        font-size: 1.6rem !important;
    }
}
